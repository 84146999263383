<template>
  <div>
    <b-card>
      <h3>
        Dodaj nowy sprzęt
      </h3>
      <b-form-input
        v-model="equipment.name"
        placeholder="Nazwa sprzętu"
        required
      />
      <b-form-input
        v-model="equipment.quantity"
        placeholder="Ilość sprzętu"
        required
        type="number"
        style="margin-top: 10px;"
      />
    </b-card>
    <b-card>
      <h2>
        Wybierz usługi które chcesz dodać do sprzętu
      </h2>
      <div
        v-for="Offer in offers"
        :key="Offer"
      >
        <b-form-select
          v-model="Offer.id"
          :options="alloffers"
          placeholder="Wybierz usługę"
          required
          style="margin-bottom: 10px;"
        />
      </div>
      <button
        class="btn btn-success"
        style="width: 100%; padding: 10px;"
        @click="newDay ()"
      >
        Dodaj usługę
      </button>
    </b-card>
    <b-card>
      <b-button
        type="submit"
        variant="primary"
        style=" width: 100%;"
        @click="addEquipment"
      >
        Dodaj sprzęt
      </b-button>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardBody, BCardFooter, BForm, BFormInput, BButton, BFormSelect,
} from 'bootstrap-vue'
import swal from 'sweetalert2'

export default {
  name: 'EquipmentAdd',
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BForm,
    BFormInput,
    BButton,
    BFormSelect,
  },
  data() {
    return {
      equipment: {
        name: '',
        description: '',
        category_id: '',
        brand_id: '',
        model_id: '',
        serial_number: '',
        purchase_date: '',
        purchase_price: '',
        warranty_date: '',
        warranty_price: '',
        status: '',
        notes: '',
        created_at: '',
        updated_at: '',
      },
      categories: [],
      brands: [],
      models: [],
      errors: [],
      offers: [
        {
          id: '',
          equipment_id: '',
          offer_id: '',
          created_at: '',
          updated_at: '',
        },
      ],
      alloffers: [
      ],
    }
  },
  mounted() {
    document.title = 'Dodaj sprzęt'
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }
    axios.get(`${process.env.VUE_APP_API_URL}my-offers`, config)
      .then(response => {
        this.alloffers = response.data.success
        for (let i = 0; i < this.alloffers.length; i++) {
          this.alloffers[i].value = this.alloffers[i].id
          this.alloffers[i].text = this.alloffers[i].title
        }
      })
  },
  methods: {
    newDay() {
      this.offers.push({
        id: '',
        equipment_id: '',
        offer_id: '',
        created_at: '',
        updated_at: '',
      })
    },
    addEquipment() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.post(`${process.env.VUE_APP_API_URL}equipment`, {
        name: this.equipment.name,
        quantity: this.equipment.quantity,
        offers: this.offers,
      }, config)
        .then(response => {
          if (response.data.success) {
            swal.fire({
              title: 'Sukces!',
              text: 'Sprzęt został dodany',
              type: 'success',
              confirmButtonText: 'OK',
              icon: 'success',
            })
            this.$router.push('/Sprzet')
          } else {
            this.errors = response.data.errors
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style>

</style>
